import React from "react";
import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import Page from "../../organisms/Page";
import Sidebar from "../../molecules/Sidebar";

const { baseUrl, imageCdnUrl } = domains;

import * as s from "./styles.module.scss";
import * as p from "../../organisms/Page/styles.module.scss";

export default function Equipment() {
    return (
        <Page
            metaTitle="My astrophotography equipment"
            metaDescription="Panagiotis Andreou's astrophotography equipment."
            heroImages={[
                `${imageCdnUrl}/Equipment/Setup_Solar_System_M_izhllm.jpg`, // 768 Mobile
                `${imageCdnUrl}/Equipment/Setup_Solar_System_M_izhllm.jpg`, // 992 Tablet
                `${imageCdnUrl}/Equipment/Setup_Solar_System_L_qhm9z6.jpg`, // 2880 Desktop
            ]}
            canonicalUrl={`${baseUrl}/equipment`}
            isFullBanner={false}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <Heading level={1} size="large" className={p.heading}>
                        My astrophotography equipment
                    </Heading>
                    <p>
                        The first optics I used to image deep sky astronomical
                        targets was a fast Nikon telephotos at 200mm (f/2.8). To
                        image the milky way I started with the Samyang wide lens
                        at 14mm (f2/.8) that I replaced with the excellent AF-S
                        NIKKOR 14-24mm f/2.8G ED. I was shooting with a stock
                        un-modified Nikon D750 and the tracker was a Star
                        Adventurer with a Manfrotto/Gitzo tripod. My very first
                        tripod, the one I used to shoot my very first milky way
                        images in Kefalonia island was a small Gorrilapod!
                    </p>
                    <p>
                        I am still using wide DSLR lenses, especially when I am
                        travelling to dark skies. One of my most used DSLR lens
                        is the Samyang 135mm f/2 which is very sharp.
                    </p>
                    <p>
                        Now, I am using various setups. All of them are using
                        the legendary Takahashi EM-400 mount guided with the
                        GT-40 guiding scope and Japanese refractors, Takahashi
                        FC-60CB, FC-100DZ and FS-85EDP with their dedicated
                        reducers and flatteners that lower their focal ratio
                        considerably to allow me to capture more photons faster
                        or just improve their image quality across the image
                        field. When travelling, I am also using the iOptron
                        CEM25EC that is very protable and has a precision
                        optical encoder system.
                    </p>
                    <p>
                        My favourite DSLR is a 36.3-megapixel Japanese
                        astronomical camera from Nikon, the D810A, that
                        transmits light from the hydrogen-alpha spectral line at
                        a rate that is four times higher than normal cameras,
                        allowing me to capture faint details of nebulae that
                        emit on that wavelength when I have access of a dark
                        sky.
                    </p>
                    <p>
                        For narrowband and broadband imaging, I am using a
                        Chinese astronomical CMOS camera from ZWO, the ASI
                        1600MM Pro Cooled Monochrome cooled down at -20C. With
                        special astronomical 1.25' filters from Astrodon (USA)
                        attached to a ZWO 8-position filter wheel, I can capture
                        specific light wavelengths (Hydrogen-alpha, Oxygen III,
                        Surlfur II, Luminance, RGB, Nitrogen II etc.) and
                        combine them using variations of the Hubble palette.
                    </p>
                    <p>
                        <img
                            src={`${imageCdnUrl}/Equipment/em-400-b_qzoig9.jpg`}
                            title="Imaging with FSQ-85EDP and EM-400"
                            alt="Imaging with FSQ-85EDP and EM-400"
                            className={s.image}
                            style={{ maxHeight: 750 }}
                        />
                        <br />
                        <i>Imaging with FSQ-85EDP and EM-400</i>
                    </p>
                    <p>
                        My main astronomical processing software of choice is
                        PixInsight. I am also using Photoshop for final touches,
                        mostly colour balance etc. My main capturing software is
                        SharpCap Pro. The polar allignment is usually done with
                        just the bult-in polarscope of the mounts especially
                        when I am usiong the EM-400 and its super accurate
                        built-in polarscope (I am rarely using the SharpCap Pro
                        for polar alignment). Focusing is done manually with
                        various Bahtinov masks or the DSLR screen when imaging
                        with a DSLR and lenses. I will probably add soon an
                        automatic focuser.
                    </p>
                    <p>
                        I am also using the Software Bisque TheSky desktop
                        software to frame targets and enable GoTo capabilities
                        on my EM-400 mount. When I am guiding my mounts (usually
                        that is the EM-400) I am using the PHD2 guding software.
                        Often, especially when imaging birght deep sky objects
                        such as the Andromeda galaxy or bright comets, I am
                        manually targeting the objects by simply using a
                        guidescope and the hand controller of the mount, with
                        some extra help of one my binoculars when needed to
                        locate the targets in the night sky - I find this really
                        rewarding so I am trying to do it whenever I can and the
                        seeing is great.
                    </p>
                </div>
                <Sidebar />
            </div>
        </Page>
    );
}
